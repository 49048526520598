.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.number-vertical {
    display: inline;
    position: absolute;
    transform: translateX(-100%) translateY(-50%) rotate(90deg);
    transform-origin: 100% 50%;
}

.number-diagonal {
    display: inline;
    position: absolute;
    transform: translateX(-100%) translateY(-50%) rotate(45deg);
    transform-origin: 100% 50%;
}

.number-other-diagonal {
    display: inline;
    position: absolute;
    transform: translateX(-100%) translateY(-50%) rotate(-45deg);
    transform-origin: 100% 50%;
}

.number-horizontal {
    text-align: right;
    width: 5ch;
}

.cell-rotated-center {
    text-align: center;
    vertical-align: bottom;
    height: 2ch;
}

.cell-rotated-right {
    text-align: right;
    vertical-align: bottom;
}

.board-table {
    /* Negate the width of .number-horizontal so the board is centered while ignoring label text. */
    margin-left: -5ch;
}

hr {
    margin-top: 8ch;
    width: 20ch;
}
